const burgerMenu = document.querySelector("#burger-menu");
const burgerMenuOpen = document.querySelector('.navbar-burger');
const burgerMenuClose = document.querySelector('.navbar-close');
const burgerAnchors = document.querySelectorAll('#burger-menu a');

burgerMenuOpen.addEventListener('click', () => {
    burgerMenu.classList.remove("hidden");
});

const handleCloseBurger = () => {
    burgerMenu.classList.add("hidden");
}

burgerMenuClose.addEventListener('click', () => {
    handleCloseBurger();
})

burgerAnchors.forEach(anchor => {
    anchor.addEventListener('click', () => {
        handleCloseBurger();
    })
})